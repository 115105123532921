import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["listContainer", "paginationSection", "searchText"];

    connect() {
        //Lang code
        this.langCode = this.mainController.getLangCode();
    }

    async loadSearchList(page) {

        //Loading
        this.mainController.pageLoading();

        const dataObj = {
            text: this.searchTextTarget.value,
            inputCurrentPage: page
        };

        fetch('/' + this.langCode + '/_SearchListBlocks', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObj)
        }).then((response) => {
            if (response.ok) {
                return response.text();
            }
            else {
                //Error handling
                throw new Error('response not ok');
            }
        }).then((responseText) => {

            const res = document.createElement('div');
            res.innerHTML = responseText;

            var resSearchListBlock = res.querySelector("#searchListBlock");
            var resPaginationBlock = res.querySelector("#paginationBlock");

            this.listContainerTarget.innerHTML = resSearchListBlock.innerHTML;
            this.paginationSectionTarget.innerHTML = resPaginationBlock.innerHTML;

            //Loaded
            this.mainController.pageLoaded();

        }).catch((error) => {
            console.log(error);

            //Loaded
            this.mainController.pageLoaded();
        });
    }

    async prevPage(event) {
        event.preventDefault();

        var currentPage = parseInt(event.currentTarget.closest('.js-pagination-container').getAttribute('data-current-page'));

        if (currentPage > 1) {
            await this.loadSearchList(currentPage - 1);
        }
    }

    async nextPage(event) {
        event.preventDefault();

        var currentPage = parseInt(event.currentTarget.closest('.js-pagination-container').getAttribute('data-current-page'));
        var totalPages = parseInt(event.currentTarget.closest('.js-pagination-container').getAttribute('data-total-pages'));

        if (currentPage < totalPages) {
            await this.loadSearchList(currentPage + 1);
        }
    }

    /*
        Main Controller
    */
    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }
}