import { Controller } from "stimulus"
import Swiper, { Navigation, Pagination, Lazy, Autoplay } from 'swiper';

Swiper.use([Navigation, Pagination, Lazy, Autoplay]);

export default class extends Controller {
    static targets = ["heroSwiper"];

    connect() {
        this.swiperInstances = [];

        if (this.hasHeroSwiperTarget) {
            this.initHeroSwiper();
        }

    }

    disconnect() {
        /* Distruggo gli slider inizializzati in questo controller */
        if (this.swiperInstances.length > 0) {
            for (var i = this.swiperInstances.length - 1; i >= 0; --i) {
                this.swiperInstances[i].instance.destroy();
                this.swiperInstances.splice(i, 1);
            }
        }
    }

    initHeroSwiper() {
        const swiperKey = "hero-slider";

        Array.from(this.heroSwiperTargets).forEach((target, index) => {
            this.swiperInstances.push({
                key: swiperKey + index,
                instance: new Swiper(target, {
                    speed: 600,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    resistanceRatio: 0,
                    preloadImages: false,
                    //loop: true,
                    //autoplay: {
                    //    delay: 5000,
                    //    disableOnInteraction: false
                    //},
                    lazy: {
                        loadPrevNext: true,
                    },
                    navigation: {
                        nextEl: target.querySelector('.swiper-button-next'),
                        prevEl: target.querySelector('.swiper-button-prev'),
                    },
                    pagination: {
                        el: target.querySelector('.swiper-pagination'),
                        type: 'bullets',
                        clickable: true
                    }
                })
            });
            if (target.querySelectorAll('.swiper-slide').length <= 1) {
                target.classList.add("--noswiping");
            }
        });
    }

    //prendo main_controller.js
    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }

}