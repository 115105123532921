import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [""];

    connect() {
        
    }

    allowAccess(e) {
        e.preventDefault();
        this.mainController.setCookie(this.mainController.accessCookie, this.mainController.accessAllowedCookieValue);
        this.element.classList.remove('--open');
        this.element.classList.add('--closed');
        document.body.style.overflow = '';
    }

    denyAccess(e) {
        e.preventDefault();
        this.mainController.setCookie(this.mainController.accessCookie, this.mainController.accessNotAllowedCookieValue);
        //document.location.href = document.location.href;
        this.element.querySelector('.access-box__buttons').parentNode.removeChild(this.element.querySelector('.access-box__buttons'));
        this.element.querySelector('.txt').innerHTML = `<p>${_('You must be at least 18 years old to taste our beers and browse our website.')}</p><p>${_('Drink responsibly')}</p>`;
        //this.element.querySelector('.txt').innerHTML = '<p>' + _("Per gustare la nostra birra devi aver compiuto almeno 18 anni.") + '</p><p>' + _("Drink responsibly") + '</p>';
    }

    //prendo main_controller.js
    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }

}