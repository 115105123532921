import { Controller } from "stimulus";
import { gsap, Cubic } from "gsap/all";

export default class extends Controller {
    static targets = ["shareLayer", "shareButton", "shareOverlay"]

    connect() {
        //init share overlay
        this.overlayInit();

        var isScrolling = false;
        var scrollLimit = Math.max(document.body.scrollHeight, document.body.offsetHeight,
            document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;
        var ftHeight = document.getElementById('ft').offsetHeight;
        var st = this.mainController.supportPageOffset ? window.pageYOffset : this.mainController.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

        //update value on scroll
        window.addEventListener('scroll', () => {
            st = this.mainController.supportPageOffset ? window.pageYOffset : this.mainController.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            scrollLimit = Math.max(document.body.scrollHeight, document.body.offsetHeight,
                document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;
            ftHeight = document.getElementById('ft').offsetHeight;

            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {

                    this.updateButtonValue(st, ftHeight, scrollLimit);
                    this.updateShareOverlayValue(st, ftHeight, scrollLimit);

                    isScrolling = false;
                })
            }
        });

        //update value on resize
        window.addEventListener('resize', () => {
            st = this.mainController.supportPageOffset ? window.pageYOffset : this.mainController.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            scrollLimit = Math.max(document.body.scrollHeight, document.body.offsetHeight,
                document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;
            ftHeight = document.getElementById('ft').offsetHeight;

            this.updateButtonValue(st, ftHeight, scrollLimit);
            this.updateShareOverlayValue(st, ftHeight, scrollLimit);
            this.overlaySizes();
        });
    }

    openShareLayer(e) {
        e.preventDefault();

        this.mainController.bodyNoScroll();
        this.shareLayerTarget.classList.remove('--close');
        this.shareLayerTarget.classList.add('--open');

        gsap.to(this.shareOverlayTarget.querySelector('span'), .5, {
            translateZ: 0,
            scaleX: 1,
            scaleY: 1,
            ease: Cubic.easeIn
        });
    }

    closeShareLayer(e) {
        e.preventDefault();

        this.mainController.bodyScroll();
        this.shareLayerTarget.classList.remove('--open');
        this.shareLayerTarget.classList.add('--close');

        setTimeout(() => {
            gsap.to(this.shareOverlayTarget.querySelector('span'), .5, {
                translateZ: 0,
                scaleX: 0,
                scaleY: 0,
                ease: Cubic.easeIn
            });
        }, 100);
    }

    /*
     *  Share Button
     */

    updateButtonValue(st, ftHeight, scrollLimit) {
        var shareBottom;
        if (window.innerWidth <= 1024) {
            shareBottom = 40;
        } else {
            shareBottom = 40;
        }

        if (st > document.getElementById('hd').offsetHeight) {
            this.shareButtonTarget.classList.add('--on');

            if (this.mainController.elementIsInView(document.getElementById('ft'))) {
                this.shareButtonTarget.style.bottom = ftHeight - (scrollLimit - st) + shareBottom + 'px';
            } else {
                this.shareButtonTarget.style.bottom = '';
            }

        } else {
            this.shareButtonTarget.classList.remove('--on');
        }
    }


    /*
        Share Overlay
     */
    overlayInit() {
        gsap.set(this.shareOverlayTarget.querySelector('span'), {
            scaleX: 0,
            scaleY: 0,
            translateZ: 0
        });

        this.overlaySizes();
    }

    overlaySizes() {
        var diameterValue = Math.sqrt(Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2)) * 2;
        gsap.set(this.shareOverlayTarget.querySelector('span'), {
            height: diameterValue + 'px',
            width: diameterValue + 'px',
            top: -(diameterValue / 2) + 'px',
            left: -(diameterValue / 2) + 'px'
        });
    }

    updateShareOverlayValue(st, ftHeight, scrollLimit) {
        var shareOverlayBottom = 65;
        if (st > document.getElementById('hd').offsetHeight) {
            if (this.mainController.elementIsInView(document.getElementById('ft'))) {
                document.querySelector('.share .share-overlay').style.bottom = ftHeight - (scrollLimit - st) + shareOverlayBottom + 'px';
            } else {
                document.querySelector('.share .share-overlay').style.bottom = '';
            }
        }
    }

    /*
        Share Social
     */

    share(e) {
        e.preventDefault();

        const shareLink = location.href;
        const shareText = document.querySelector('input[name="shareText"]').value;
        const social = e.target;
        if (social.classList.contains('icon-facebook')) {
            window.open("https://www.facebook.com/sharer.php?u=" + encodeURIComponent(shareLink), "share", "height=440,width=900,scrollbars=true");
        } else if (social.classList.contains('icon-twitter')) {
            var twitterShare = "https://twitter.com/intent/tweet?url=" + encodeURIComponent(shareLink);
            if (shareText.length) {
                twitterShare = twitterShare + "&text=" + encodeURIComponent(shareText);
            }
            window.open(twitterShare, "twitter", "width=600,height=400");
        } else if (social.classList.contains('icon-whatsapp')) {
            var whatsappShare = "https://api.whatsapp.com/send?text=" + encodeURIComponent(shareLink);
            window.open(whatsappShare, "whatsapp", "width=600,height=400");
        } else if (social.classList.contains("icon-linkedin")) {
            var linkedinShare = "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(shareLink);
            if (shareText.length) {
                linkedinShare = linkedinShare + "&title=" + encodeURIComponent(shareText);
            }
            window.open(linkedinShare, "linkedin", "width=600,height=400");
        }
    }

    /*
        Main Controller
    */

    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }
}