import { Controller } from "stimulus";
import { gsap, Circ, ScrollToPlugin } from "gsap/all";
import Swiper from 'swiper';

function getParameterValueByName(parameterName) {
    var layoutParams = document.querySelector('.js-form-params');

    return layoutParams.querySelector('input[name="' + parameterName + '"]').value;
}

gsap.registerPlugin(ScrollToPlugin);

export default class extends Controller {
    static targets = ["filterSlider", "beerCard"];

    connect() {

        if (window.location.href.includes('?')) {
            this.filterByTypeOnLoad();
        }

        //if (window.location.href.includes('#type=')) {
        //    var selectedType = window.location.href.split('#type=')[1];
        //    console.log(selectedType);
        //    this.filterByTypeOnLoad(selectedType)
        //}

        if (!this.mainController.isTouchDevice()) {
            this.element.querySelectorAll('.beer-card').forEach(beer => {
                this.updateBeerCardValue(beer);
            });
        }

        if (this.hasFilterSliderTarget) {
            this.initFilterSlider();
            window.addEventListener('resize', () => {
                this.initFilterSlider();
            });
        }
    }

    updateBeerCardValue(beer) {
        var beerInfo = beer.querySelector('.beer-card__info');
        var beerDiv = beerInfo.querySelector('div');
        var beerDivHeight = beerDiv.offsetHeight;
        var beerDivTop = 40;
        if (window.innerWidth < 1024) {
            beerDivTop = 25;
        }
        var beerInfoTranslate = beerDivHeight / 2 + beerDivTop / 2;
        gsap.set(beerInfo, { y: beerInfoTranslate });
        beerInfo.setAttribute('data-translate', beerInfoTranslate);

        var tlEnter = new gsap.timeline({ paused: true, ease: Circ.easeOut });
        var tlLeave = new gsap.timeline({ paused: true, ease: Circ.easeOut });

        tlEnter.to(beerInfo, 0.3, { y: 0 })
            .to(beerDiv, 0.3, { opacity: 1 }, 0)

        tlLeave.to(beerInfo, 0.3, { y: beerInfo.getAttribute('data-translate') })
            .to(beerDiv, 0.3, { opacity: 0 }, 0);

        beer.addEventListener('mouseenter', () => {
            tlEnter.play(0);
        });
        beer.addEventListener('mouseleave', () => {
            tlLeave.play(0);
        });
    }

    /*
        Slider Filtri
     */

    initFilterSlider() {
        var sliderWidth = this.filterSliderTarget.getBoundingClientRect().width;
        var sliderScrollWidth = 0;

        var sliderItems = [].slice.call(this.filterSliderTarget.querySelectorAll('.swiper-slide'));
        sliderItems.forEach((item) => {
            var itemStyle = item.currentStyle || window.getComputedStyle(item);
            var itemWidth = item.getBoundingClientRect().width + parseFloat(itemStyle.marginRight);
            sliderScrollWidth += itemWidth;
        });

        if (sliderScrollWidth > sliderWidth) {
            this.filterSliderTarget.classList.remove("--noswiping");
            new Swiper(this.filterSliderTarget, {
                slidesPerView: 'auto',
                freeMode: true,
                freeModeMomentumBounce: false,
                freeModeSticky: true,
                spaceBetween: 0,
                resistanceRatio: 0,
                //slideToClickedSlide: true
            });
        }
        else {
            this.filterSliderTarget.classList.add("--noswiping");
        }
    }

    /*
        Filtro
     */

    filterByType(e) {
        e.preventDefault();

        var typeId = e.currentTarget.getAttribute('data-type');
        const categorySlug = e.currentTarget.getAttribute("data-category");
        //Settaggio url
        const currentUrl = window.location.href;

        if (window.history && window.history.replaceState) {
            if (currentUrl.includes('?')) {
                window.history.replaceState('', '', currentUrl.split('=')[0] + '=' + categorySlug);
            }
            else {
                window.history.replaceState('', '', currentUrl + '?=' + categorySlug);
            }
        }

        //Sistemazione filtri
        const selectedClassName = "--selected";
        const filterAll = document.getElementById("all-categories-selected");
        filterAll.classList.remove(selectedClassName);

        const filters = document.querySelectorAll(".js-typology-categories li a");

        for (const filter of filters) {
            filter.classList.remove(selectedClassName);
        }

        e.currentTarget.classList.add('--selected');

        //filtro
        //this.beerCardTargets.forEach(card => {
        //    if (type === '0') {
        //        card.style.display = ''
        //    }
        //    else {
        //        if (card.getAttribute('data-type') != type) {
        //            card.style.display = 'none'
        //        }
        //        else {
        //            card.style.display = ''
        //        }
        //    }
        //})

        //Caricamento lista
        this.getList(typeId);

        /* history.replaceState({}, "", "#type=" + type);*/
        //select
        //e.currentTarget.classList.add('--selected');
        //this.mainController.getSiblings(e.currentTarget.closest('.swiper-slide')).forEach((item) => {
        //    item.querySelector('a').classList.remove('--selected');
        //});

        
    }

    filterByTypeOnLoad() {

       const categorySlug = window.location.href.split('=')[1];

        //Sistemazione filtri
        const selectedClassName = "--selected";
        const filterAll = document.getElementById("all-categories-selected");
        filterAll.classList.remove(selectedClassName);

        const filters = document.querySelectorAll(".js-typology-categories li");

        for (const filter of filters) {
            filter.classList.remove(selectedClassName);
        }

        document.querySelector('.js-typology-categories [data-category="' + categorySlug + '"]').classList.add(selectedClassName);

        var typeId=document.querySelector('.js-typology-categories [data-category="' + categorySlug + '"]').getAttribute("data-type");

        //Caricamento lista
        this.getList(typeId);


        //select
        //this.filterSliderTarget.querySelector('a[data-type="' + type + '"]').classList.add('--selected');
        //this.mainController.getSiblings(this.filterSliderTarget.querySelector('a[data-type="' + type + '"]').closest('.swiper-slide')).forEach((item) => {
        //    item.querySelector('a').classList.remove('--selected');
        //});

        ////filtro
        //this.beerCardTargets.forEach(card => {
        //    if (type === '0') {
        //        card.style.display = ''
        //    }
        //    else {
        //        if (card.getAttribute('data-type') != type) {
        //            card.style.display = 'none'
        //        }
        //        else {
        //            card.style.display = ''
        //        }
        //    }
        //})
    }

    getList(typeId) {

        //select
        //this.filterSliderTarget.querySelector('a[data-type="' + typeId + '"]').classList.add('--selected');
        //this.mainController.getSiblings(this.filterSliderTarget.querySelector('a[data-type="' + typeId + '"]').closest('.swiper-slide')).forEach((item) => {
        //    item.querySelector('a').classList.remove('--selected');
        //});

        //filtro
        this.beerCardTargets.forEach(card => {
            if (typeId === '0') {
                card.style.display = ''
            }
            else {
                if (card.getAttribute('data-type') != typeId) {
                    card.style.display = 'none'
                }
                else {
                    card.style.display = ''
                }
            }
        })
        //const dataObj = {
        //    typeId: typeId
        //};

        //var languageCode = getParameterValueByName('languageCode');

        //fetch('/' + languageCode + '/_CategoriesListBlocks', {
        //    method: 'POST',
        //    headers: {
        //        'Content-Type': 'application/json'
        //    },
        //    body: JSON.stringify(dataObj)
        //}).then((response) => {
        //    if (response.ok) {
        //        return response.text();
        //    }
        //    else {
        //        //Error handling
        //        throw new Error('response not ok');
        //    }
        //}).then((responseText) => {
        //    const div = document.createElement("div");
        //    div.innerHTML = responseText;

        //    if (div.querySelector('div')) {
        //        document.getElementById('js-container-product-category-list').innerHTML = responseText;
        //        //setTimeout(() => {
        //        //    this.masonryReload();
        //        //}, 300);
        //    } else {
        //        /*this.masonryTarget.classList.remove("is-loading");*/
        //    }
        //}

        //).catch((error) => {
        //    console.log(error);
        //});
    }

    //prendo main_controller.js
    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }
}