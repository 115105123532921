import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["contactButton", "contactButtonMessage"]

    connect() {

        var isScrolling = false;
        var scrollLimit = Math.max(document.body.scrollHeight, document.body.offsetHeight,
            document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;
        var ftHeight = document.getElementById('ft').offsetHeight;
        var st = this.mainController.supportPageOffset ? window.pageYOffset : this.mainController.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

        //update value on scroll
        window.addEventListener('scroll', () => {
            st = this.mainController.supportPageOffset ? window.pageYOffset : this.mainController.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            scrollLimit = Math.max(document.body.scrollHeight, document.body.offsetHeight,
                document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;
            ftHeight = document.getElementById('ft').offsetHeight;

            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {

                    this.updateButtonValue(st, ftHeight, scrollLimit);

                    isScrolling = false;
                })
            }
        });

        //update value on resize
        window.addEventListener('resize', () => {
            st = this.mainController.supportPageOffset ? window.pageYOffset : this.mainController.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            scrollLimit = Math.max(document.body.scrollHeight, document.body.offsetHeight,
                document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;
            ftHeight = document.getElementById('ft').offsetHeight;

            this.updateButtonValue(st, ftHeight, scrollLimit);
        });
    }

    /*
     *  Contact Button
     */

    updateButtonValue(st, ftHeight, scrollLimit) {
        var btnBottom;
        if (window.innerWidth <= 768) {
            btnBottom = 100;
        } else {
            btnBottom = 40;
        }

        if (st > document.getElementById('hd').offsetHeight) {
            this.contactButtonTarget.classList.add('--on');

            if (this.mainController.elementIsInView(document.getElementById('ft'))) {
                this.contactButtonTarget.style.bottom = ftHeight - (scrollLimit - st) + btnBottom + 'px';
                //this.contactButtonMessageTarget.classList.add('--remove');
            } else {
                this.contactButtonTarget.style.bottom = '';
                //this.contactButtonMessageTarget.classList.remove('--remove');
            }

        } else {
            this.contactButtonTarget.classList.remove('--on');
        }
    }

    removeContactButtonMessage(e) {
        e.preventDefault();
        this.contactButtonMessageTarget.classList.add('--remove');
        setTimeout(() => {
            this.contactButtonMessageTarget.parentNode.removeChild(this.contactButtonMessageTarget);
        }, 310);
    }

    /*
        Main Controller
    */

    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }
}