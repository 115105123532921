import { Controller } from "stimulus";

export default class extends Controller {

    connect() {
        
    }

    changeTab(e) {
        e.preventDefault();

        var tabBtn = e.currentTarget;
        var tabBtnId = tabBtn.getAttribute('data-tab-id');
        var tabsContainer = document.querySelector('.tabs-container');
        this.mainController.getSiblings(tabBtn).forEach(item => {
            item.classList.remove('--selected');
        });
        tabBtn.classList.add('--selected');

        tabsContainer.querySelectorAll('.tab').forEach(tab => {
            var tabId = tab.getAttribute('data-tab-id');
            if (tabBtnId == tabId) {
                tab.style.display = '';
                tab.classList.add('--active');
            } else {
                tab.style.display = 'none';
                tab.classList.remove('--active');
            }

            if (!this.mainController.isTouchDevice()) {
                tab.querySelectorAll('.beer-card').forEach(beer => {
                    this.beersController.updateBeerCardValue(beer);
                });
            }
            
        }); 
    }

    get beersController() {
        return this.application.getControllerForElementAndIdentifier(document.querySelector('.section.--beers-section'), "beers");
    }

    //prendo main_controller.js
    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }


}