import { Controller } from "stimulus"
import Swiper, { Navigation, Pagination, Lazy } from 'swiper';
import { gsap } from "gsap";

Swiper.use([Navigation, Pagination, Lazy]);

export default class extends Controller {
    static targets = ["parallaxImage", "launchSlider", "imageSlider", "imageSliderFullscreen"];

    connect() {
        this.swiperInstances = [];

        //Parallax Image
        if (this.hasParallaxImageTarget) {
            if (window.innerWidth >= 768) {
                this.initParallaxImage();
            }
        }
        //Slider immagini
        if (this.hasImageSliderTarget) {
            this.initImageSlider();
        }
        //Slider immagini fullscreen
        if (this.hasImageSliderFullscreenTarget) {
            this.initImageSliderFullscreen();
        }
        //Slider lancio
        if (this.hasLaunchSliderTarget) {
            this.initLaunchSlider();
        }
    }

    disconnect() {
        /* Distruggo gli slider inizializzati in questo controller */
        if (this.swiperInstances.length > 0) {
            for (var i = this.swiperInstances.length - 1; i >= 0; --i) {
                this.swiperInstances[i].instance.destroy();
                this.swiperInstances.splice(i, 1);
            }
        }
    }

    /*
        Parallax Image
     */
    initParallaxImage() {
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        var isScrolling = false;


        window.addEventListener('scroll', () => {
            var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {

                    var localScroll,
                        totalScroll,
                        actualScroll,
                        bottomWindow = st + window.innerHeight;

                    this.parallaxImageTargets.forEach((parallaxEl) => {
                        var parallaxContainerTop = parallaxEl.closest(".row").offsetTop;
                        localScroll = bottomWindow - parallaxContainerTop;
                        totalScroll = parallaxEl.offsetHeight + window.innerHeight;
                        actualScroll = (localScroll * 50) / totalScroll;

                        if (bottomWindow > parallaxContainerTop) {
                            gsap.set(parallaxEl, { yPercent: - actualScroll })
                        }
                    });

                    isScrolling = false;
                })
            }
        })
    }

    /*
        Slider Immagini
     */
    initImageSlider() {
        const swiperKey = "image-slider";

        Array.from(this.imageSliderTargets).forEach((target, index) => {
            this.swiperInstances.push({
                key: swiperKey + index,
                instance: new Swiper(target, {
                    slidesPerView: 1,
                    spaceBetween: 60,
                    resistanceRatio: 0,
                    autoHeight: true,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                    },
                    navigation: {
                        nextEl: target.closest('.swiper').querySelector('.swiper-button-next'),
                        prevEl: target.closest('.swiper').querySelector('.swiper-button-prev'),
                    },
                    pagination: {
                        el: target.closest('.swiper').querySelector('.swiper-pagination'),
                        type: 'bullets',
                        clickable: true
                    }
                })
            });
            if (target.querySelectorAll('.swiper-slide').length <= 1) {
                target.closest('.swiper').classList.add("--noswiping");
            }
        });
    }

    /*
        Slider Immagini Fullscreen
     */
    initImageSliderFullscreen() {
        const swiperKey = "image-slider-fullscreen";

        Array.from(this.imageSliderFullscreenTargets).forEach((target, index) => {
            this.swiperInstances.push({
                key: swiperKey + index,
                instance: new Swiper(target, {
                    slidesPerView: 1,
                    spaceBetween: 60,
                    resistanceRatio: 0,
                    autoHeight: true,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                    },
                    navigation: {
                        nextEl: target.closest('.swiper').querySelector('.swiper-button-next'),
                        prevEl: target.closest('.swiper').querySelector('.swiper-button-prev'),
                    },
                    pagination: {
                        el: target.closest('.swiper').querySelector('.swiper-pagination'),
                        type: 'bullets',
                        clickable: true
                    }
                })
            });
            if (target.querySelectorAll('.swiper-slide').length <= 1) {
                target.closest('.swiper').classList.add("--noswiping");
            }
        });
    }

    /*
        Slider Lancio
     */
    initLaunchSlider() {
        const swiperKey = "launch-slider";

        Array.from(this.launchSliderTargets).forEach((target, index) => {
            this.swiperInstances.push({
                key: swiperKey + index,
                instance: new Swiper(target, {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    resistanceRatio: 0,
                    watchSlidesVisibility: true,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                    },
                    navigation: {
                        nextEl: target.closest('.swiper').querySelector('.swiper-button-next'),
                        prevEl: target.closest('.swiper').querySelector('.swiper-button-prev'),
                    },
                    pagination: {
                        el: target.closest('.swiper').querySelector('.swiper-pagination'),
                        type: 'bullets',
                        clickable: true
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 60
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 60
                        }
                    }
                })
            });
            if (window.innerWidth >= 1024 && target.querySelectorAll('.swiper-slide').length <= 3) {
                target.closest('.swiper').classList.add("--noswiping");
            } else if (window.innerWidth >= 768 && window.innerWidth < 1024 && target.querySelectorAll('.swiper-slide').length <= 2) {
                target.closest('.swiper').classList.add("--noswiping");
            } else if (window.innerWidth < 768 && target.querySelectorAll('.swiper-slide').length <= 1) {
                target.closest('.swiper').classList.add("--noswiping");
            } 
        });
    }

    //prendo main_controller.js
    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }

}