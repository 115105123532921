import { Controller } from "stimulus"
import Swiper, { Thumbs, Lazy } from 'swiper';
Swiper.use([Thumbs, Lazy]);

export default class extends Controller {
    static targets = ["mapList", "mapListContainer"];

    connect() {
        this.setMapListContainerHeight();

        window.addEventListener('resize', () => {
            this.setMapListContainerHeight();
        });
    }

    setMapListContainerHeight() {
        var mapHeight = this.mapListTarget.offsetHeight;
        var breadHeight = this.mapListTarget.querySelector('#breadcrumbs').offsetHeight;
        var filtersHeight = this.mapListTarget.querySelector('.map__list-filters').offsetHeight;
        var filtersStyle = this.mapListTarget.querySelector('.map__list-filters').currentStyle || window.getComputedStyle(this.mapListTarget.querySelector('.map__list-filters'));
        var filtersTop = parseInt(filtersStyle.marginTop);
        if (window.innerWidth > 1024) {
            this.mapListContainerTarget.style.height = mapHeight - (breadHeight + filtersHeight + filtersTop) + 'px';
        }
        else {
            this.mapListContainerTarget.style.height = '';
        }
    }

    //prendo main_controller.js
    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }

}