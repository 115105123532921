import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["mandatoryField"];

    connect() {
        //Lang code
        this.langCode = this.mainController.getLangCode();

        //rimozione classe errore
        this.mandatoryFieldTargets.forEach((field) => {
            field.addEventListener('focus', () => {
                if (field.type == 'radio') {
                    field.closest('.custom-radio').classList.remove('--error');
                } else {
                    field.closest('.form-field').classList.remove('--error');
                }
            });
        });
    }

    submitForm(e) {
        e.preventDefault();

        var form = this.element.querySelector('form');

        if (this.checkForm(form)) {
            this.doForm(form);
        }
    }

    checkForm(form) {
        var notice = false;

        this.mandatoryFieldTargets.forEach((field) => {
            //check input(no radio) e select
            if (field.type != 'radio' && (field.value.replace(/\s+/g, '') == '' || (field.nodeName === 'SELECT' && (field.value == null || field.value.replace(/\s+/g, '') == '0')))) {
                notice = true;
                field.closest('.form-field').classList.add('--error');
            }

            //check input radio
            if (field.type == 'radio') {
                //check newsletter privacy
                if (field.id == "newsletterSubAgree" && !field.checked) {
                    notice = true;
                    field.closest('.custom-radio').classList.add('--error');
                }
                //check privacy
                if (field.id == "newsletterPrivacyAgree" && !field.checked) {
                    notice = true;
                    field.closest('.custom-radio').classList.add('--error');
                }
            }
        });

        if (notice) {
            return false;
        }

        //check email
        var emailInput = form.querySelector('input[type=email][name=newsletterEmail]');
        if (emailInput) {
            var trimEmail = emailInput.value.replace(/\s+/g, '');
            if (!this.mainController.validateEmail(trimEmail)) {
                emailInput.closest('.form-field').classList.add('--error');
                this.mainController.showAlert(_('Email non valida'));
                return false;
            }
        }

        return true;
    }

    doForm(form) {

        //loader
        this.mainController.pageLoading();

        var newsletterValue = this.element.querySelector('select[name="newsletterOwner"]').value;
        var newsletterOwner = (newsletterValue != '') ? (newsletterValue == '1' ? true : false) : null;

        const dataObj = {
            newsletterOwner: newsletterOwner,
            newsletterName: this.element.querySelector('input[name="newsletterName"]').value,
            newsletterSurname: this.element.querySelector('input[name="newsletterSurname"]').value,
            newsletterEmail: this.element.querySelector('input[name="newsletterEmail"]').value,
            newsletterProvince: this.element.querySelector('select[name="newsletterProvince"]').value
        };

        fetch('/' + this.langCode + '/DoNewsletterRegistration', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                '__RequestVerificationToken': this.element.querySelector('input[name="__RequestVerificationToken"]').value
            },
            body: JSON.stringify(dataObj)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            else {
                //Error handling
                throw new Error('response not ok');
            }
        }).then((responseJson) => {
            if (responseJson.status) {

                this.mainController.pageLoaded();

                this.mainController.executeCloseNewsletterPopup();

                this.mainController.showSuccess(_("Richiesta inviata con successo"));
            }
            else {
                this.mainController.showAlert(responseJson.message);

                this.mainController.pageLoaded();
            }
        }).catch((error) => {
            console.log(error);

            //Error handling
            this.mainController.showAlert(_("Errore nell'invio della richiesta. Riprova più tardi"));

            this.mainController.pageLoaded();
        });
    }

    //prendo main_controller.js
    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }

}