import { Controller } from "stimulus"
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import LazyLoad from "vanilla-lazyload";
import { gsap, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin);
import Swiper, { Navigation, Pagination, Lazy, Scrollbar, Zoom } from 'swiper';
Swiper.use([Navigation, Pagination, Lazy, Scrollbar, Zoom]);

export default class extends Controller {
    static targets = ["beerSwiper", "newsSwiper", "alertPopup", "shopPopup", "shopPopupGlobal", "newsletterPopup", "reservationPopup", "contactPopup", "accessPopup", "effectInput", "effectSelect", "effectTextarea", "downloadPopup", "zoomPopup"];


    /*
        Run functions ---- connect == load pagina
    */

    connect() {
        //Current lang code
        this.langCode = this.getLangCode();

        this.accessCookie = "allowAccess"
        this.accessAllowedCookieValue = "1";
        this.accessNotAllowedCookieValue = "0";
        this.scrollbarWidth = 0;
        this.swiperInstances = [];

        this.supportPageOffset = window.pageXOffset !== undefined;
        this.isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        
        //this.preventScrollbarJump();
        this.vh();
        //this.getScrollbarWidth();

        window.addEventListener("resize", () => {
            //this.preventScrollbarJump();
            this.vh();
            //this.getScrollbarWidth();
        }, true);

        this.IEdetect();

        if (this.isTouchDevice()) {
            document.documentElement.classList.add('touchevents');
        }
        else {
            document.documentElement.classList.add('no-touchevents');
        }

        //popup accesso
        if (this.hasAccessPopupTarget) {
            //Apro in javascript in modo tale che il googlebot non la veda direttamente aperta
            this.accessPopupTarget.classList.add('--open');
            //this.checkSiteAccess();
        }

        this.lazyImages();

        this.onLoadAnimation();

        this.fixHdOnLoad();
        this.fixHdOnScroll();

        if (this.hasBeerSwiperTarget) {
            this.initBeerSwiper();
        }

        if (this.hasNewsSwiperTarget) {
            this.initNewsSwiper();
        }

        //Form
        this.checkInputValueForEffect();
        this.checkTextareaValueForEffect();
        this.checkSelectValueForEffect();
    }

    disconnect() {
        /* Distruggo gli slider inizializzati in questo controller */
        if (this.swiperInstances.length > 0) {
            for (var i = this.swiperInstances.length - 1; i >= 0; --i) {
                this.swiperInstances[i].instance.destroy();
                this.swiperInstances.splice(i, 1);
            }
        }
    }

    /*
        Utils
    */

    vh() {
        const vh = window.innerHeight * 0.01;
        this.element.style.setProperty("--vh", `${vh}px`);
    }

    IEdetect() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        var ieAlert = document.createElement('div');
        ieAlert.classList.add('ie-alert');
        ieAlert.innerHTML = "<img src='/images/logo_verde.svg' class='logo' alt='a3cube' width='152' /><img src='/images/ie-logo.png' class='ie-logo' /><p>Stai usando un browser non compatibile con questo sito.<br />Ti consigliamo di <a href='https://browsehappy.com/' target='_blank'>aggiornarlo o cambiarlo</a> per migliorare la tua esperienza e la tua sicurezza.</p>";

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            document.documentElement.classList.add('ie');
            document.body.appendChild(ieAlert);
        }

        return false;
    }

    isTouchDevice() {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

        var mq = function (query) {
            return window.matchMedia(query).matches;
        };

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }

    isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    bodyNoScroll() {
        disableBodyScroll(this.bodyTarget);
    }

    bodyScroll() {
        enableBodyScroll(this.bodyTarget);
    }

    preventScrollbarJump() {
        var inlineBodyWidth = parseInt(document.body.style.width);
        if (inlineBodyWidth) {
            document.body.style.width = '';
            document.body.style.width = document.body.offsetWidth + "px";
        }
        else {
            document.body.style.width = document.body.offsetWidth + "px";
        }
    }

    getScrollbarWidth() {
        this.scrollbarWidth = window.innerWidth - document.body.offsetWidth;
    }

    getSiblings(elem) {
        // Setup siblings array and get the first sibling
        var siblings = [];
        var sibling = elem.parentNode.firstChild;

        // Loop through each sibling and push to the array
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== elem) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling
        }

        return siblings;
    };

    elementIsInView(el) {
        //chek if at least a part of the element is in viewport
        var myElement = el;
        var bounding = myElement.getBoundingClientRect();
        var myElementHeight = myElement.offsetHeight;
        var myElementWidth = myElement.offsetWidth;

        var bounding = myElement.getBoundingClientRect();

        if (bounding.top >= -myElementHeight
            && bounding.left >= -myElementWidth
            && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + myElementWidth
            && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + myElementHeight) {
            return true;
        }
    }

    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    stopEventPropagation(e) {
        e.stopPropagation();
    }

    /*
        LazyLoad Images
    */

    lazyImages() {
        this.ll = new LazyLoad({
            elements_selector: ".lazy",
            callback_loaded: element => {
                if (element.classList.contains("loaded")) {
                    element.classList.remove("loaded");
                } else {
                    const loader = element.closest(".loaded");
                    if (loader) {
                        loader.classList.remove("loaded");
                    }
                }
                element.removeAttribute("data-src");
            },
            callback_error: element => {
                if (element.classList.contains("loaded")) {
                    element.classList.remove("loaded");
                } else {
                    const loader = element.closest(".loaded");
                    if (loader) {
                        loader.classList.remove("loaded");
                    }
                }
            }
        });
    }

    /*
        Popup Alert
    */
    showAlert(txt) {
        var alertIcon = '<div class="f-modal-icon f-modal-error animate"><span class="f-modal-x-mark"><span class="f-modal-line f-modal-left animateXLeft"></span><span class="f-modal-line f-modal-right animateXRight"></span></span><div class="f-modal-placeholder"></div><div class="f-modal-fix"></div></div>';

        this.alertPopupTarget.querySelector('.alert-popup__inner .alert-icon').innerHTML = alertIcon;
        this.alertPopupTarget.querySelector('.alert-popup__inner .alert-txt').innerText = txt;
        this.alertPopupTarget.classList.remove('--closed');
        this.alertPopupTarget.classList.add('--open');
    }

    showSuccess(title, txt) {
        var icon = '<div class="f-modal-icon f-modal-success animate"><span class="f-modal-line f-modal-tip animateSuccessTip"></span><span class="f-modal-line f-modal-long animateSuccessLong"></span><div class="f-modal-placeholder"></div><div class="f-modal-fix"></div></div>';

        this.alertPopupTarget.querySelector('.alert-popup__inner .alert-icon').innerHTML = icon;
        this.alertPopupTarget.querySelector('.alert-popup__inner .alert-txt span').innerHTML = title;
        if (txt) {
            var alertTxt = document.createElement('div');
            this.alertPopupTarget.querySelector('.alert-popup__inner .alert-txt').appendChild(alertTxt);
            this.alertPopupTarget.querySelector('.alert-popup__inner .alert-txt div').innerHTML = txt;

        }
        this.alertPopupTarget.classList.remove('--closed');
        this.alertPopupTarget.classList.add('--open');
    }

    closeAlert(e) {
        e.preventDefault();

        this.alertPopupTarget.classList.remove('--open');
        this.alertPopupTarget.classList.add('--closed');
    }

    getParameterValueByName(parameterName) {
        var layoutParams = document.querySelector('.js-form-params');

        return layoutParams.querySelector('input[name="' + parameterName + '"]').value;
    }

    getLangCode() {
        return this.getParameterValueByName('languageCode');
    }

    /*
        Go back
    */
    goBack(e) {
        if (document.referrer.length > 0 && document.referrer.indexOf(window.location.host) != -1) { //Se vengo da una pagina del mio sito faccio il back del browser
            e.preventDefault();

            history.go(-1);
        }
    }

    /*
     *  Load Animation
     */
    onLoadAnimation() {
        setTimeout(() => {
            //document.getElementById('pageLoadOverlay').classList.add('--fade-out');
            document.body.classList.add('--loaded');
        }, 100);
    }

    /*
     *  Accesso Sito 18+
     */
    //checkSiteAccess() {
    //    if (this.getCookie(this.accessCookie) === this.accessAllowedCookieValue) {
    //        //console.log('accesso consentito, accedi al sito');
    //    }
    //    else if (this.getCookie(this.accessCookie) === this.accessNotAllowedCookieValue) {
    //        //console.log('accesso non consentito, redirect pagina');
    //    }
    //    else {
    //        //console.log('cookie non presente, popup accesso');
    //    }
    //}


    /*
     *  Menu
     */

    openMenu(e) {
        e.preventDefault();
        document.getElementById('hd').classList.add('--menu-is-open');
        document.getElementById('hd').querySelector('.hd__menu-btn').classList.add('--close');
        document.getElementById('menu').classList.remove('--closed');
        document.getElementById('menu').classList.add('--open');
        document.getElementById('menuOverlay').classList.add('--on');
        if (document.querySelector('.beers-anchors')) {
            document.querySelector('.beers-anchors').classList.add('--menu-is-open');
        }
        this.bodyNoScroll();
    }
    closeMenu(e) {
        e.preventDefault();
        document.getElementById('hd').classList.remove('--menu-is-open');
        document.getElementById('hd').querySelector('.hd__menu-btn').classList.remove('--close');
        document.getElementById('menu').classList.remove('--open');
        document.getElementById('menu').classList.add('--closed');
        document.getElementById('menuOverlay').classList.remove('--on');
        if (document.querySelector('.beers-anchors')) {
            document.querySelector('.beers-anchors').classList.remove('--menu-is-open');
        }
        setTimeout(() => {
            this.bodyScroll();
        }, 310);
    }

    /*
     *  Fix Header
     */

    fixHdOnLoad() {
        var st = this.supportPageOffset ? window.pageYOffset : this.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        if (st > 0) {
            document.getElementById('hd').classList.add('--fixed');
        }
    }

    fixHdOnScroll() {
        var isScrolling = false;
        window.addEventListener('scroll', () => {
            var st = this.supportPageOffset ? window.pageYOffset : this.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {
                    if (st > 0) {
                        document.getElementById('hd').classList.add('--fixed');
                    } else {
                        document.getElementById('hd').classList.remove('--fixed');
                    }
                    isScrolling = false;
                })
            }
        });
    }


    /*
     *  Ricerca
     */

    openSearchLayer(e) {
        e.preventDefault();
        if (!document.body.classList.contains('search-page')) {
            document.getElementById('hd').querySelector('.hd__btn-search').classList.add('--close');
            document.getElementById('hd').classList.add('--searchlayer-open');
            document.getElementById('searchLayer').classList.remove('--closed');
            document.getElementById('searchLayer').classList.add('--open');
            this.bodyNoScroll();
            setTimeout(() => { document.querySelector('#searchLayer input[type="search"]').focus(); }, 400);
        }
        else {
            gsap.to(window, {
                duration: 0.6,
                scrollTo: '#searchLayer',
                onComplete: () => {
                    document.querySelector('#searchLayer input[type="search"]').focus();
                }
            });
        }
    }
    closeSearchLayer(e) {
        e.preventDefault();
        document.getElementById('hd').querySelector('.hd__btn-search').classList.remove('--close');
        //document.getElementById('hd').classList.remove('--searchlayer-open');
        document.getElementById('searchLayer').classList.remove('--open');
        document.getElementById('searchLayer').classList.add('--closed');
        setTimeout(() => {
            document.getElementById('hd').classList.remove('--searchlayer-open');
            this.bodyScroll();
        }, 310);
    }

    /*
     *  Loader Popup
     */

    pageLoading() {
        document.getElementById('spinnerLoader').classList.add('--loading');
    }

    pageLoaded() {
        document.getElementById('spinnerLoader').classList.remove('--loading');
    }

    /*
     *  Shop Popup
     */
    executeOpenProductShopListPopup(productId) {
        //Reset popup content
        this.shopPopupTarget.querySelector('.js-product-shop-list-container').innerHTML = '';

        //Open popup
        this.bodyNoScroll();
        this.shopPopupTarget.classList.remove('--closed');
        this.shopPopupTarget.classList.add('--open');

        const dataObj = {
            productId: productId
        };

        //Loading
        this.pageLoading();

        fetch('/' + this.langCode + '/_ProductShopList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObj)
        }).then((response) => {
            if (response.ok) {
                return response.text();
            }
            else {
                //Error handling
                throw new Error('response not ok');
            }
        }).then((responseText) => {

            this.shopPopupTarget.querySelector('.js-product-shop-list-container').innerHTML = responseText;

            //Loaded
            this.pageLoaded();

        }).catch((error) => {
            console.log(error);

            //Loaded
            this.pageLoaded();
        });
    }

    openShopPopup(e) {
        e.preventDefault();

        var productId = e.currentTarget.getAttribute('data-product-id');

        this.executeOpenProductShopListPopup(productId);
    }
    closeShopPopup(e) {
        e.preventDefault();
        this.bodyScroll();
        this.shopPopupTarget.classList.remove('--open');
        this.shopPopupTarget.classList.add('--closed');
    }

    /*
     *  Shop Popup global
     */
    openShopPopupGlobal(e) {
        e.preventDefault();
        this.bodyNoScroll();
        this.shopPopupGlobalTarget.classList.remove('--closed');
        this.shopPopupGlobalTarget.classList.add('--open');
    }
    closeShopPopupGlobal(e) {
        e.preventDefault();
        this.bodyScroll();
        this.shopPopupGlobalTarget.classList.remove('--open');
        this.shopPopupGlobalTarget.classList.add('--closed');
    }

    /*
     *  Newsletter Popup
     */

    openNewsletterPopup(e) {
        e.preventDefault();
        //this.bodyNoScroll();
        this.newsletterPopupTarget.classList.remove('--closed');
        this.newsletterPopupTarget.classList.add('--open');
    }
    executeCloseNewsletterPopup() {
        //this.bodyScroll();
        this.newsletterPopupTarget.classList.remove('--open');
        this.newsletterPopupTarget.classList.add('--closed');
    }
    closeNewsletterPopup(e) {
        e.preventDefault();

        this.executeCloseNewsletterPopup();
    }

    /*
     *  Reservation Popup
     */

    openReservationPopup(e) {
        e.preventDefault();
        this.bodyNoScroll();
        this.reservationPopupTarget.classList.remove('--closed');
        this.reservationPopupTarget.classList.add('--open');
    }
    executeCloseReservationPopup() {
        this.bodyScroll();
        this.reservationPopupTarget.classList.remove('--open');
        this.reservationPopupTarget.classList.add('--closed');
    }
    closeReservationPopup(e) {
        e.preventDefault();
        this.bodyScroll();

        this.executeCloseReservationPopup();
    }

    /*
     *  Contact Popup
     */

    openContactPopup(e) {
        e.preventDefault();
        this.bodyNoScroll();
        this.contactPopupTarget.classList.remove('--closed');
        this.contactPopupTarget.classList.add('--open');
    }
    executeCloseContactPopup() {
        this.bodyScroll();
        this.contactPopupTarget.classList.remove('--open');
        this.contactPopupTarget.classList.add('--closed');
    }
    closeContactPopup(e) {
        e.preventDefault();

        this.executeCloseContactPopup();
    }

    /*
     *  CBeer Fullscreen Zoom Popup
     */

    openZoomPopup(e) {
        e.preventDefault();
        this.bodyNoScroll();
        this.zoomPopupTarget.classList.remove('--closed');
        this.zoomPopupTarget.classList.add('--open');

        var zoomSlider = document.querySelector('#zoomPopup .swiper-container');
        var zoomSliderContent = e.currentTarget.closest('.swiper-wrapper').cloneNode(true);
        zoomSliderContent.querySelectorAll('.swiper-slide').forEach((slide) => { slide.removeAttribute('data-action') });
        zoomSlider.querySelector('.swiper-wrapper').innerHTML = zoomSliderContent.innerHTML;
        var startIndex = [...e.currentTarget.parentElement.children].indexOf(e.currentTarget);
        this.zoomPopupFullscreenImageSlider(zoomSlider, startIndex);
    }
    executeCloseZoomPopup() {
        this.bodyScroll();
        this.zoomPopupTarget.classList.remove('--open');
        this.zoomPopupTarget.classList.add('--closed');

        var zoomSlider = document.querySelector('#zoomPopup .swiper-container');
        setTimeout(() => {
            zoomSlider.classList.remove('--noswiping');
            zoomSlider.swiper.destroy();
            zoomSlider.querySelector('.swiper-wrapper').innerHTML = "";
        }, 300)
    }
    closeZoomPopup(e) {
        e.preventDefault();

        this.executeCloseZoomPopup();
    }

    zoomPopupFullscreenImageSlider(zoomSlider, startIndex) {

        var zoomSwiper = new Swiper(zoomSlider, {
            initialSlide: startIndex,
            slidesPerView: 1,
            resistanceRatio: 0,
            zoom: {
                maxRatio: 5,
            },
            navigation: {
                nextEl: zoomSlider.querySelector('.swiper-button-next'),
                prevEl: zoomSlider.querySelector('.swiper-button-prev'),
            },
            pagination: {
                el: zoomSlider.querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true
            }
        })

        if (zoomSlider.querySelectorAll('.swiper-slide').length <= 1) {
            zoomSlider.classList.add("--noswiping");
        }

        //Array.from(this.zoomImageSliderTargets).forEach((target, index) => {
        //    this.swiperInstances.push({
        //        key: swiperKey + index,
        //        instance: new Swiper(target, {
        //            preloadImages: false,
        //            lazy: {
        //                loadPrevNext: true,
        //            },
        //            slidesPerView: 1,
        //            resistanceRatio: 0,
        //            navigation: {
        //                nextEl: target.querySelector('.swiper-button-next'),
        //                prevEl: target.querySelector('.swiper-button-prev'),
        //            },
        //        })
        //    });

        //    if (target.querySelectorAll('.swiper-slide').length <= 1) {
        //        target.classList.add("--noswiping");
        //    }

        //});
    }

    /*
     *  Newsletter Popup
     */

    openDownloadPopup(e) {
        e.preventDefault();
        //this.bodyNoScroll();
        this.downloadPopupTarget.classList.remove('--closed');
        this.downloadPopupTarget.classList.add('--open');
    }
    closeDownloadPopup(e) {
        e.preventDefault();
        //this.bodyScroll();
        this.downloadPopupTarget.classList.remove('--open');
        this.downloadPopupTarget.classList.add('--closed');
    }

    /*
     *  Beer Swiper 
     */
    initBeerSwiper() {
        const swiperKey = "beer-slider";

        Array.from(this.beerSwiperTargets).forEach((target, index) => {
            this.swiperInstances.push({
                key: swiperKey + index,
                instance: new Swiper(target, {
                    slidesPerView: 1,
                    spaceBetween: 60,
                    resistanceRatio: 0,
                    watchSlidesVisibility: true,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                    },
                    navigation: {
                        nextEl: target.closest('.swiper').querySelector('.swiper-button-next'),
                        prevEl: target.closest('.swiper').querySelector('.swiper-button-prev'),
                    },
                    pagination: {
                        el: target.closest('.swiper').querySelector('.swiper-pagination'),
                        type: 'bullets',
                        clickable: true
                    },
                    scrollbar: {
                        el: target.closest('.swiper').querySelector('.swiper-scrollbar'),
                        draggable: true,
                    },
                    breakpoints: {
                        601: {
                            slidesPerView: 2,
                            spaceBetween: 60
                        },
                        1281: {
                            slidesPerView: 3,
                            spaceBetween: 60
                        }
                    }
                })
            });
            if (window.innerWidth > 1280 && target.querySelectorAll('.swiper-slide').length <= 3) {
                target.closest('.swiper').classList.add("--noswiping");
            } else if (window.innerWidth <= 1280 && window.innerWidth > 600 && target.querySelectorAll('.swiper-slide').length <= 2) {
                target.closest('.swiper').classList.add("--noswiping");
            } else if (window.innerWidth <= 600 && target.querySelectorAll('.swiper-slide').length <= 1) {
                target.closest('.swiper').classList.add("--noswiping");
            }
        });
    }

    /*
    *  News Swiper 
    */
    initNewsSwiper() {
        const swiperKey = "beer-slider";

        Array.from(this.newsSwiperTargets).forEach((target, index) => {
            this.swiperInstances.push({
                key: swiperKey + index,
                instance: new Swiper(target, {
                    slidesPerView: 1,
                    spaceBetween: 60,
                    resistanceRatio: 0,
                    navigation: {
                        nextEl: target.closest('.swiper').querySelector('.swiper-button-next'),
                        prevEl: target.closest('.swiper').querySelector('.swiper-button-prev'),
                    },
                    pagination: {
                        el: target.closest('.swiper').querySelector('.swiper-pagination'),
                        type: 'bullets',
                        clickable: true
                    },
                    breakpoints: {
                        426: {
                            slidesPerView: 2,
                            spaceBetween: 60
                        },
                        769: {
                            slidesPerView: 3,
                            spaceBetween: 60
                        }
                    }
                })
            });
            if (window.innerWidth > 768 && target.querySelectorAll('.swiper-slide').length <= 3) {
                target.closest('.swiper').classList.add("--noswiping");
            } else if (window.innerWidth <= 768 && window.innerWidth > 425 && target.querySelectorAll('.swiper-slide').length <= 2) {
                target.closest('.swiper').classList.add("--noswiping");
            } else if (window.innerWidth <= 425 && target.querySelectorAll('.swiper-slide').length <= 1) {
                target.closest('.swiper').classList.add("--noswiping");
            }
        });
    }

    /*
     *  News Category List Mobile 
     */

    toggleNewsCategoryList(e) {
        e.preventDefault();

        if (window.innerWidth <= 1024) {
            e.currentTarget.classList.toggle('--list-open');
            e.currentTarget.closest('.news-buttons').querySelector('ul').classList.toggle('--open');
        }
    }

    /*
     *  Input Effect
     */
    checkInputValueForEffect() {
        this.effectInputTargets.forEach((input) => {
            input.addEventListener('focusout', () => {
                if (input.value.replace(/\s+/g, '') != '') {
                    input.closest('.input-effect').classList.add('has-content');
                } else {
                    input.closest('.input-effect').classList.remove('has-content');
                }
            });
        });
    }

    /*
     *  Textarea Effect
     */
    checkTextareaValueForEffect() {
        this.effectTextareaTargets.forEach((textarea) => {
            textarea.addEventListener('focusout', () => {
                if (textarea.value.replace(/\s+/g, '') != '') {
                    textarea.closest('.textarea-effect').classList.add('has-content');
                } else {
                    textarea.closest('.textarea-effect').classList.remove('has-content');
                }
            });
        });
    }

    /*
     *  Select Effect
     */
    checkSelectValueForEffect() {
        this.effectSelectTargets.forEach((select) => {
            select.addEventListener('change', () => {
                if (!select.value) {
                    select.closest('.select-effect').classList.remove('has-content');
                    select.blur();
                } else {
                    select.closest('.select-effect').classList.add('has-content');
                }
            });
        });
    }
}

