import { Controller } from "stimulus";
import { gsap } from "gsap/all";
import Swiper, { Thumbs, Lazy } from 'swiper';
Swiper.use([Thumbs, Lazy]);

export default class extends Controller {
    static targets = ["beerRow", "beerSlider", "beerSliderController"];

    connect() {
        this.swiperInstances = [];

        if (this.hasBeerSliderControllerTarget) {
            this.checkBeerSliderControllerSlides();
            this.setBeerSliderControllerHeight();
            //this.setInfoListHeight();
        }

        if (this.hasBeerSliderTarget) {
            this.initBeerSlider();
        }

        window.addEventListener('resize', () => {
            if (this.hasBeerRowTarget) {
                //this.setInfoListHeight();
                this.resetInfoListHeight();
            }
            if (this.hasBeerSliderControllerTarget) {
                this.checkBeerSliderControllerSlides();
                this.setBeerSliderControllerHeight();
            }
        });
    }

    disconnect() {
        /* Distruggo gli slider inizializzati in questo controller */
        if (this.swiperInstances.length > 0) {
            for (var i = this.swiperInstances.length - 1; i >= 0; --i) {
                this.swiperInstances[i].instance.destroy();
                this.swiperInstances.splice(i, 1);
            }
        }
    }

    /*
     *  Slider
     */

    setBeerSliderControllerHeight() {
        if (window.innerWidth > 1024) {
            this.beerRowTargets.forEach(row => {
                var rowSliderHeight = row.querySelector('.beer-row__img-slider').offsetHeight;
                row.querySelector('.beer-row__img-controls').style.height = rowSliderHeight + 'px';
            });
        } else {
            this.beerRowTargets.forEach(row => {
                row.querySelector('.beer-row__img-controls').style.height = '';
            });
        }
    }

    checkBeerSliderControllerSlides() {
        if (window.innerWidth > 1024) {
            this.beerSliderControllerTargets.forEach(controlSlider => {
                controlSlider.closest('.beer-row__img-controls').style.display = '';
            });
        } else {
            this.beerSliderControllerTargets.forEach(controlSlider => {
                if (controlSlider.querySelectorAll('.swiper-slide').length < 2) {
                    controlSlider.closest('.beer-row__img-controls').style.display = 'none';
                }
            });
        }
    }

    initBeerSlider() {
        const swiperKey = "beer-slider";
        const swiperControllerKey = "beer-slider-controller";

        Array.from(this.beerSliderControllerTargets).forEach((target, index) => {
            this.swiperInstances.push({
                key: swiperControllerKey + index,
                instance: new Swiper(target, {
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                    resistanceRatio: 0,
                    watchSlidesVisibility: true,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                    },
                    breakpoints: {
                        1025: {
                            direction: 'vertical'
                        }
                    }
                })
            });
        });

        Array.from(this.beerSliderTargets).forEach((target, index) => {
            this.swiperInstances.push({
                key: swiperKey + index,
                instance: new Swiper(target, {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    resistanceRatio: 0,
                    watchSlidesVisibility: true,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                    },
                    thumbs: {
                        swiper: this.swiperInstances.find(x => x.key === swiperControllerKey + index).instance
                    }
                })
            });
            if (target.querySelectorAll('.swiper-slide').length <= 1) {
                target.classList.add("--noswiping");
            }
        });

    }

    /*
     *  Info List
     */

    setInfoListHeight() {
        var infoListBaseLength = 8;
        var itemMarginBottom = 10;
        this.beerRowTargets.forEach(row => {
            var infoList = row.querySelector('.info-list');
            var infoListItems = row.querySelectorAll('.info-list li');
            var infoListHeight = 0;
            for (var i = 0; i < infoListBaseLength; i++) {
                infoListHeight = infoListHeight + infoListItems[i].offsetHeight;
            }
            infoListHeight = infoListHeight + (itemMarginBottom * (infoListBaseLength - 1));
            infoList.setAttribute('data-height', infoListHeight);
            infoList.style.height = infoListHeight + 'px';
        });
    }

    toggleInfoList(e) {
        e.preventDefault();
        e.currentTarget.closest('.beer-row__info').querySelector('.info-list').classList.toggle('--open');
        e.currentTarget.classList.toggle('--close');
        if (e.currentTarget.closest('.beer-row__info').querySelector('.info-list').classList.contains('--open')) {
            e.currentTarget.textContent = e.currentTarget.getAttribute('data-label-close');
            //gsap.to(e.currentTarget.closest('.beer-row__info').querySelector('.info-list'), {duration: 0.3, height: 'auto'});
        } else {
            e.currentTarget.textContent = e.currentTarget.getAttribute('data-label-open');
            //gsap.to(e.currentTarget.closest('.beer-row__info').querySelector('.info-list'), { duration: 0.3, height: e.currentTarget.closest('.beer-row__info').querySelector('.info-list').getAttribute('data-height') });
        }
    }

    toggleMobileInfoList(e) {
        if (window.innerWidth <= 1024) {
            e.preventDefault();
            e.currentTarget.classList.toggle('--open');
            e.currentTarget.closest('.beer-row__info').querySelector('.info-list-container').classList.toggle('--open');
            e.currentTarget.closest('.beer-row__info').querySelector('.info-list').classList.toggle('--open');
            if (e.currentTarget.closest('.beer-row__info').querySelector('.info-list').classList.contains('--open')) {
                gsap.to(e.currentTarget.closest('.beer-row__info').querySelector('.info-list-container'), { duration: 0.3, height: 'auto' });
            } else {
                gsap.to(e.currentTarget.closest('.beer-row__info').querySelector('.info-list-container'), { duration: 0.3, height: 0 });
            }
        }
    }

    resetInfoListHeight() {
        if (window.innerWidth > 1024) {
            this.beerRowTargets.forEach(row => {
                row.querySelector('.beer-row__info').querySelector('span').classList.remove('--open');
                row.querySelector('.beer-row__info').querySelector('.info-list-container').classList.remove('--open');
                row.querySelector('.beer-row__info').querySelector('.info-list-container').style.height = '';
                row.querySelector('.beer-row__info').querySelector('.info-list').classList.remove('--open');
            })
        }
    }

    /*
     *  Info Box 
     */

    toggleInfoBox(e) {
        e.preventDefault();

        var parentPos = e.currentTarget.closest('li').getBoundingClientRect(),
            childPos = e.currentTarget.getBoundingClientRect(),
            relativePos = {};

        relativePos.top = childPos.top - parentPos.top,
            relativePos.right = childPos.right - parentPos.right,
            relativePos.bottom = childPos.bottom - parentPos.bottom,
            relativePos.left = childPos.left - parentPos.left;

        if (window.innerWidth > 1024) {
            e.currentTarget.closest('div').querySelector('.info-box').style.left = relativePos.left + e.currentTarget.offsetWidth / 2 + "px";
        } else {
            e.currentTarget.closest('div').querySelector('.info-box').style.left = "";
            this.mainController.bodyNoScroll();
        }
        this.closeAllInfoBoxes();
        e.currentTarget.closest('div').querySelector('.info-box').classList.toggle('--open');
    }

    closeInfoBox(e) {
        e.preventDefault();
        e.currentTarget.closest('.info-box').classList.remove('--open');
        if (window.innerWidth <= 1024) {
            this.mainController.bodyScroll();
        }
    }

    closeAllInfoBoxes() {
        document.querySelectorAll('.info-box').forEach((box) => {
            box.classList.remove('--open');
        });
    }

    //prendo main_controller.js
    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }

}