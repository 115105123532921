import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["banner", "popup"];

    connect() {
        this.ckEssential = "ckss";
        this.ckPerformance = "ckpr";

        if (document.cookie.indexOf(this.ckPerformance) >= 0) {
            this.bannerTarget.remove();

            if (this.getCookie(this.ckPerformance) === "1") {
                this.popupTarget.querySelector('input[name="performance"][value="1"]').checked = true;
            } else {
                this.popupTarget.querySelector('input[name="performance"][value="0"]').checked = true;
            }
        } else {
            //this.popupTarget.querySelector('input[name="performance"][value="1"]').checked = true;
            setTimeout(() => {
                this.bannerTarget.classList.add('on');
            }, 1000);
        }
    }

    getCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    saveAll() {
        document.cookie = this.setCookie(this.ckEssential, "1", 180);
        document.cookie = this.setCookie(this.ckPerformance, "1", 180);
        location.reload();
    }

    save() {
        document.cookie = this.setCookie(this.ckEssential, "1", 180);
        document.cookie = this.setCookie(this.ckPerformance, this.popupTarget.querySelector('input[name="performance"]:checked').value, 180);
        window.location.href = window.location.href;
    }

    openPopup(e) {
        e.preventDefault();
        this.popupTarget.classList.add("is--open");
    }

    closePopup(e) {
        e.preventDefault();
        this.popupTarget.classList.remove("is--open");
    }

    preventClosePopup(event) {
        event.stopPropagation();
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
}