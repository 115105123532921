import { Controller } from "stimulus";
import { gsap, Circ, ScrollToPlugin } from "gsap/all";
import Swiper from 'swiper';

gsap.registerPlugin(ScrollToPlugin);


export default class extends Controller {
    static targets = ["anchorSlider"];

    connect() {
        if (!this.mainController.isTouchDevice()) {
            this.element.querySelectorAll('.beer-card').forEach(beer => {

                this.updateBeerCardValue(beer);

            });
        }

        if (this.hasAnchorSliderTarget) {
            this.anchorSliderTop = this.anchorSliderTarget.closest('.beers-anchors').offsetTop;
            this.paddingValue = 40;
            if (window.innerWidth < 768) {
                this.paddingValue = 0;
            }
            this.hdFixedHeight = 100;
            if (window.innerWidth < 425) {
                this.hdFixedHeight = 70;
            }
            this.fixAnchorSliderOnLoad();
            this.fixAnchorSliderOnScroll();
            this.activeAnchorOnScroll();
            this.initAnchorSlider();
            window.addEventListener('resize', () => {
                this.initAnchorSlider();
                if (window.innerWidth < 768) {
                    this.paddingValue = 0;
                } else {
                    this.paddingValue = 40;
                }
                if (window.innerWidth < 425) {
                    this.hdFixedHeight = 70;
                } else {
                    this.hdFixedHeight = 100;
                }
            });
        }
    }


    updateBeerCardValue(beer) {
        var beerInfo = beer.querySelector('.beer-card__info');
        var beerDiv = beerInfo.querySelector('div');
        var beerDivHeight = beerDiv.offsetHeight;
        var beerDivTop = 40;
        if (window.innerWidth < 1024) {
            beerDivTop = 25;
        }
        var beerInfoTranslate = beerDivHeight / 2 + beerDivTop / 2;
        gsap.set(beerInfo, { y: beerInfoTranslate });
        beerInfo.setAttribute('data-translate', beerInfoTranslate);

        var tlEnter = new gsap.timeline({ paused: true, ease: Circ.easeOut });
        var tlLeave = new gsap.timeline({ paused: true, ease: Circ.easeOut });

        tlEnter.to(beerInfo, 0.3, { y: 0 })
            .to(beerDiv, 0.3, { opacity: 1 }, 0)

        tlLeave.to(beerInfo, 0.3, { y: beerInfo.getAttribute('data-translate') })
            .to(beerDiv, 0.3, { opacity: 0 }, 0);

        beer.addEventListener('mouseenter', () => {
            tlEnter.play(0);
        });
        beer.addEventListener('mouseleave', () => {
            tlLeave.play(0);
        });
    }

    /*
        Slider Ancore
     */

    fixAnchorSliderOnLoad() {
        var st = this.mainController.supportPageOffset ? window.pageYOffset : this.mainController.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        if (st > this.anchorSliderTop - document.getElementById('hd').offsetHeight) {
            this.anchorSliderTarget.closest('.beers-anchors').classList.add('--fixed');
            this.anchorSliderTarget.closest('.--beers-section').querySelector('.tabs-container').style.paddingTop = this.anchorSliderTarget.closest('.beers-anchors').offsetHeight + 80 + 'px';
        }
    }

    fixAnchorSliderOnScroll() {
        var isScrolling = false;
        

        window.addEventListener('scroll', () => {
            var st = this.mainController.supportPageOffset ? window.pageYOffset : this.mainController.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {
                    if (st > this.anchorSliderTop - document.getElementById('hd').offsetHeight) {
                        this.anchorSliderTarget.closest('.beers-anchors').classList.add('--fixed');
                        this.anchorSliderTarget.closest('.--beers-section').querySelector('.tabs-container').style.paddingTop = this.paddingValue + this.anchorSliderTarget.closest('.beers-anchors').offsetHeight + 'px';
                    } else {
                        this.anchorSliderTarget.closest('.beers-anchors').classList.remove('--fixed');
                        this.anchorSliderTarget.closest('.--beers-section').querySelector('.tabs-container').style.paddingTop = '';
                    }
                    isScrolling = false;
                })
            }
        });
    }

    initAnchorSlider() {
        var sliderWidth = this.anchorSliderTarget.getBoundingClientRect().width;
        var sliderScrollWidth = 0;

        var sliderItems = [].slice.call(this.anchorSliderTarget.querySelectorAll('.swiper-slide'));
        sliderItems.forEach((item) => {
            var itemStyle = item.currentStyle || window.getComputedStyle(item);
            var itemWidth = item.getBoundingClientRect().width + parseFloat(itemStyle.marginRight);
            sliderScrollWidth += itemWidth;
        });

        if (sliderScrollWidth > sliderWidth) {
            this.anchorSliderTarget.classList.remove("--noswiping");
            new Swiper(this.anchorSliderTarget, {
                slidesPerView: 'auto',
                freeMode: true,
                freeModeMomentumBounce: false,
                freeModeSticky: true,
                spaceBetween: 0,
                resistanceRatio: 0,
                //slideToClickedSlide: true
            });
        }
        else {
            this.anchorSliderTarget.classList.add("--noswiping");
        }
    }

    scrollToAnchor(e) {
        e.preventDefault();
        var anchor = e.currentTarget.getAttribute('href');
        //e.currentTarget.classList.add('--selected');
        this.mainController.getSiblings(e.currentTarget.closest('.swiper-slide')).forEach((item) => {
            item.querySelector('a').classList.remove('--selected');
        });
        gsap.to(window, { duration: 0.4, scrollTo: { y: anchor, offsetY: 20 + (this.anchorSliderTarget.offsetHeight + this.hdFixedHeight) } });
    }

    activeAnchorOnScroll() {
        const anchorSections = document.querySelectorAll(".--beers-section .beers");
        const anchorItems = this.anchorSliderTarget.querySelectorAll(".swiper-slide a");
        var isScrolling = false;
        window.addEventListener('scroll', () => {
            var st = this.mainController.supportPageOffset ? window.pageYOffset : this.mainController.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {
                    let current = "";
                    anchorSections.forEach((section) => {
                        const sectionTop = section.offsetTop;
                        const sectionHeight = section.clientHeight;
                        if (st >= sectionTop - ( 60 + this.anchorSliderTarget.offsetHeight + document.getElementById('hd').offsetHeight)) {
                            current = section.getAttribute("id");
                        }
                    });

                    anchorItems.forEach((item,i) => {
                        item.classList.remove("--selected");
                        if (current != '' && item.getAttribute('href').indexOf(current) != -1) {
                            item.classList.add("--selected");
                            if (this.anchorSliderTarget.swiper) {
                                //setTimeout(() => {
                                    this.anchorSliderTarget.swiper.slideTo(i);
                                //}, 410);
                            }
                        } else if (current == '') {
                            //this.anchorSliderTarget.querySelector('.swiper-slide a').classList.add('--selected');
                        }
                    });
                    isScrolling = false;
                })
            }
        });

    }

    //prendo main_controller.js
    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }

}